import React from "react";
import styled from "styled-components";
import Header from "./Header";
import { Link } from "react-router-dom";
import { InstantSearch, connectHits } from "react-instantsearch-dom";
import { getSearchClient, indexName } from "../helpers/algoliasearch";
import ProfileCard from "./ProfileCard";
const searchClient = getSearchClient();

const CardsContainer = styled.div`
  display: grid;
  margin: 50px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px 20px;
`;

const Hits = connectHits(({ hits }) => (
  <CardsContainer>
    {hits.map((hit) => (
      <Link
        to={`/profile/${hit.id}`}
        key={hit.id}
        style={{ textDecoration: "none" }}
      >
        <ProfileCard data={hit} />
      </Link>
    ))}
  </CardsContainer>
));

const Home = () => {
  return (
    <div>
      <Header />
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Hits />
      </InstantSearch>
    </div>
  );
};

export default Home;
