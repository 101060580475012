import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "./components/Loading";
import Home from "./components/Home";
import Login from "./components/Login";
import AlumniDetail from "./components/Alumni/AlumniDetail";
import { AuthProvider } from "./helpers/authContext";
import ErrorBoundary from "./ErrorBoundaries/ErrorBoundary";
import Search from "./components/Search";
import Editor from "./components/ProjectEditor/Editor";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route path="/profile/:profileID">
                  <AlumniDetail />
                </Route>
                <Route path="/search">
                  <Search />
                </Route>
                <Route path="/new">
                  <Editor />
                </Route>


              </Switch>
            </Suspense>
          </ErrorBoundary>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
