import React, { useState } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  alpha,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
// import InputBase from '@material-ui/core/InputBase'
import SearchIcon from "@material-ui/icons/Search";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import CreateIcon from "@material-ui/icons/CreateOutlined";

//CSS imported here:
import MuiAppBar from "@material-ui/core/AppBar";
import MuiToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Avatar, ListItemIcon, ListItemText } from "@material-ui/core";
// import logo from "../images/fellow.png";

import { useAuth } from "../helpers/authContext";
import useFetchPublicDetails from "../helpers/hooks/useFetchProfileDetails";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//CSS properties defined here:
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      display: "flex",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "relative",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const SearchButton = styled(Button).attrs(() => ({
  variant: "outlined",
  fullWidth: true,
}))`
  &.MuiButton-root {
    margin: 0 16px;
    border-radius: 12px;

    @media (max-width: 880px) {
      margin: 0;
    }
  }
`;

const AppBar = styled(MuiAppBar)`
  &.MuiAppBar-colorPrimary {
    background-color: white;
    color: black;
  }
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;

  a,
  a:hover,
  a:visited {
    text-decoration: none;
    color: initial;
  }
`;

const HomeLink = styled.div`
  .app-title {
    @media (max-width: 880px) {
      display: none;
    }
  }
  display: flex;
  align-items: center;
  img.logo {
    height: 32px;
    padding: 8px;
  }
  .alpha {
    align-self: start;
    font-size: 0.8rem;
    color: #8b572a;
  }
`;
const ContainerStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`;
const ContainerEnd = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-direction: row-reverse;
`;

const UserButtonIcon = styled(Avatar)`
  &.MuiAvatar-root {
    height: 24px;
    width: 24px;
  }
`;

const Header = () => {
  const { logout } = useAuth();
  const classes = useStyles();
  const isSearchPage = useRouteMatch("/search");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const publicDetailsQuery = useFetchPublicDetails();

  const email = publicDetailsQuery.isSuccess
    ? publicDetailsQuery.data.email
    : null;

  let pictureURL = null,
    displayName = null;
  if (publicDetailsQuery.isSuccess) {
    pictureURL = publicDetailsQuery.data?.publicDetails?.pictureURL;
    displayName = publicDetailsQuery.data?.publicDetails?.displayName;
  }

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <ContainerStart>
            <Link to={`/`}>
              <HomeLink to={"/"}>
                <img className="logo" alt="" />
                <Typography variant="h6">Profile</Typography>
                <span className="alpha">alpha</span>
              </HomeLink>
            </Link>
          </ContainerStart>
          <ContainerEnd>
            <IconButton color="inherit" onClick={handleClick}>
              <UserButtonIcon src="" alt={displayName}>
                {displayName?.charAt(0)}
              </UserButtonIcon>
            </IconButton>
            <CreateIcon onClick={() => history.push("/new")} />
            <Menu
              id="simple-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>

            <div className={classes.search}>
              {!isSearchPage && (
                <SearchButton
                  startIcon={<SearchIcon />}
                  component={Link}
                  to="/search"
                >
                  Search Profiles
                </SearchButton>
              )}
            </div>
          </ContainerEnd>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
