import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { initSearchClient } from "../../helpers/algoliasearch";
import styled from "styled-components";
import get from "lodash/get";
import set from "lodash/set";
import useFetchPublicDetails from "../../helpers/hooks/useFetchProfileDetails";
import useFetchSingleProfileEmail from "../../helpers/hooks/useFetchSingleProfileEmail";
import Typography from "@material-ui/core/Typography";
import schema from "./schema.json";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import useProfileState from "./useProfileState";
// import ListField from "./ListField";
import FieldElement from "./FieldElement";
// import useActiveNavHeading from "./useActiveNavHeading";
// import useSave from "./useSave";
import Header from "../Header";
import { getPathway } from "./pathways";

const EditorContainer = styled.div`
  margin: 24px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 880px) {
    margin: 12px;
  }
  .heading {
    align-self: center;
  }
  h4 {
    scroll-margin-top: 140px;
  }
  .MuiTypography-h4 {
    margin-top: 24px;
  }
  .form-actions {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }
`;

function Editor() {
  const history = useHistory();
  const publicDetailsQuery = useFetchPublicDetails();
  const {
    isSuccess,
    isLoading: isProfileLoading,
    data: profileData,
  } = publicDetailsQuery;
  const algoliaIndex = initSearchClient();
  const { isLoading: isLoading, data: fetchedData } =
    useFetchSingleProfileEmail(isSuccess && profileData.publicDetails.email);
  const [isSaving, setSaving] = useState(false);

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  function handleClose() {
    setSnackbarOpen(false);
  }
  const handleSnackbarExit = () => {
    setSnackbarMessage(null);
  };
  function updateSnackBarMessage(message, timeout = 2000) {
    setTimeout(() => {
      setSnackbarMessage(message);
    }, timeout);
  }

  const {
    profileState,
    setInitialData,
    errors,
    updateError,
    checkHasErrors,
    checkFieldError,
    handleChange,
    hasUnsavedChanges,
    markChangesSaved,
  } = useProfileState();

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        const lastExperience =
          fetchedData.hits[0].experience[
            fetchedData.hits[0].experience.length - 1
          ];
        let profile = {};
        profile.name = profileData.publicDetails.displayName;
        profile.tfiEmail = profileData.publicDetails.email;
        profile.phone = profileData.publicDetails.mobilePhone.value;
        profile.primaryEmail = fetchedData.hits[0].primaryEmail;
        profile.objectID = fetchedData.hits[0].objectID;
        profile.education = fetchedData.hits[0].education || [];
        profile.experience = fetchedData.hits[0].experience || [];
        profile.pathway = getPathway(
          lastExperience.companyName,
          lastExperience.designation
        );
        setInitialData(profile);
      }
    }
  }, [profileData, isSuccess, setInitialData, isLoading]);

  if (isProfileLoading) return <h3>Loading</h3>;

  const saveChanges = () => {
    setSaving(true);
    setSnackbarMessage("Saving changes may take up to a minute");
    setSnackbarOpen(true);
    updateSnackBarMessage("Thank you for your patience");
    const objects = [profileState];
    algoliaIndex?.partialUpdateObjects(objects).then(({ objectIDs }) => {
      if (objectIDs) {
        console.log(objectIDs);
        setSnackbarMessage("Saved");
        setSnackbarOpen(true);
        setTimeout(() => {
          const redirectURI = `/profile/${fetchedData.hits[0].id}`;
          history.push(redirectURI);
        }, 2 * 1000);
      }
    });
  };

  const formGroups = schema.keys.map((fieldID) => {
    const fieldConfig = schema.schema[fieldID];
    return (
      <FieldElement
        fieldID={fieldID}
        value={get(profileState, fieldID)}
        fieldConfig={fieldConfig}
        key={fieldID}
        handleChange={handleChange}
        error={errors[fieldID]}
        setError={updateError}
        onBlur={checkFieldError}
      />
    );
  });
  return (
    <>
      <Header />
      <EditorContainer>
        <div className="heading">
          <Typography variant="h4" gutterBottom>
            New Profile
          </Typography>
        </div>
        {formGroups}
        <div className="form-actions">
          <Button
            variant="outlined"
            id="saveChanges"
            onClick={() => {
              if (!isSaving) saveChanges();
            }}
          >
            {isSaving ? (
              <CircularProgress size="1.5rem" variant="indeterminate" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </div>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          onExited={handleSnackbarExit}
          message={snackbarMessage}
        ></Snackbar>
      </EditorContainer>
    </>
  );
}

export default Editor;
