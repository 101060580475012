import React from "react";
// CSS imports here
import styled from "styled-components";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import { CardActionArea } from "@material-ui/core";

const Card = styled(Paper).attrs(() => ({
  elevation: 4,
}))`
  &.MuiPaper-rounded {
    border-radius: 8px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: red[500],
      width: theme.spacing(9),
      height: theme.spacing(9),
    },

    SubjectBody: {
      display: "flex",
      padding: "3px",
      margin: "0px 0px 0px 20px",
    },
    SubjectBodyP: {
      margin: "0px",
      padding: "0px 0px 0px 10px",
    },
    IconText: {
      padding: "5px 40px 0px 10px",
    },

    IconTextSchool: {
      padding: "5px 0px 0px 10px",
    },
  })
);

const ProfileCard = ({ data }) => {
  const classes = useStyles();

  return (
    // <Link to={`/profileDetail`} style={{ textDecoration: 'none' }}>
    <Card className={classes.root}>
      <CardActionArea>
        <CardHeader
          title={
            <Typography variant="h6">
              {data.firstName} {data.lastName}
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="subtitle2">{data.tfiEmail}</Typography>
              <Typography variant="subtitle2">{data.phone}</Typography>
            </div>
          }
        />
      </CardActionArea>
    </Card>
  );
};

export default ProfileCard;
