// ALL IMPORTS HERE:
import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";

//Images and Icons here:
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

// ALL CSS HERE:
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";

import useFetchSingleProfile from "../../helpers/hooks/useFetchSingleProfile";
import AlumniExperienceCard from "./AlumniExperienceCard";
import { Typography } from "@material-ui/core";

const FellowDetailsContainer = styled.div`
  margin: 24px 60px;
  text-align: center;
`;

const DetailCard = styled(Card).attrs(() => ({
  elevation: 2,
}))`
  padding: 10px;
  margin: 15px 15px;
  .MuiCardContent-root {
    padding: 0;
  }
  &.MuiPaper-rounded {
    border-radius: 16px;
  }
  &.MuiCard-root {
    overflow-x: scroll;
  }
  .text {
    font-size: 16px;
    white-space: pre-line;
  }
  .small.text {
    font-size: 16px;
  }
  .budget {
    font-size: 28px;
    font-weight: 600;
    &.raised {
    }
    &.budget-required {
      color: #6e6e6e;
    }
  }
  @media (max-width: 880px) {
    padding: 4px;
    .MuiCardContent-root {
      padding: 18px;
    }
  }
`;

const BasicCard = styled(DetailCard)`
  .MuiCardContent-root {
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    #project-summary {
      grid-column: 1/3;
    }
    @media (max-width: 880px) {
      grid-template-columns: 1fr;
      #project-summary {
        grid-column: 1/2;
      }
    }
  }
`;

const SuperBasicCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 610px) {
    grid-template-columns: 1fr;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 20px;
`;

const EmailPhoneContainer = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 10px 25px;
`;
const Email = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 0px 9px;
`;

const Title = styled.h1`
  font-size: 2 em;
  margin: 5px 15px;
`;

const CardDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 0fr 4.5fr;
  margin-top: 5px;
  grid-gap: 40px;

  @media (max-width: 880px) {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 0;
    .left {
      grid-row: 2;
    }
    .right {
      grid-row: 1;
    }
  }
`;

const FellowDetail = () => {
  const { profileID } = useParams();

  const { isLoading: isProfileLoading, data: fetchedData } =
    useFetchSingleProfile(profileID);
  if (isProfileLoading) {
    return <div>loading..</div>;
  }
  const data = fetchedData.hits[0];

  return (
    <>
      <Header />
      <FellowDetailsContainer>
        <Typography variant="h6">Basic Details</Typography>
        <BasicCard>
          {/* <Title> Basic Details</Title> */}
          <CardDetailsContainer>
            <div className="grid-container"></div>
            <div className="grid-container">
              <TitleContainer>
                <Avatar aria-label="recipe" src={data?.pictureURL} />
                <div>
                  <Title>
                    {data?.firstName} {data?.lastName}
                  </Title>
                </div>
              </TitleContainer>
              <EmailPhoneContainer>
                <EmailIcon></EmailIcon>
                <Email>{data?.tfiEmail}</Email>
              </EmailPhoneContainer>
              <EmailPhoneContainer>
                <PhoneIcon></PhoneIcon>
                <Email>{data?.phone}</Email>
              </EmailPhoneContainer>
            </div>
          </CardDetailsContainer>
        </BasicCard>
        {data?.education.length > 0 && (
          <Typography variant="h6">Education Details</Typography>
        )}
        <SuperBasicCard>
          {data?.education?.map((item) => (
            <AlumniExperienceCard item={item} />
          ))}
        </SuperBasicCard>
        {data?.experience.length > 0 && (
          <Typography variant="h6">Work Experience Details</Typography>
        )}
        <SuperBasicCard>
          {data?.experience?.map((item) => (
            <AlumniExperienceCard item={item} />
          ))}
        </SuperBasicCard>
      </FellowDetailsContainer>
    </>
  );
};

export default FellowDetail;
