const schools = [
  "iTeach",
  "Akanksha",
  "Aga",
  "Khan Academy",
  "Avsara Academy",
  "Shanti Bhavan",
  "Vikasa",
];
const communityMobilizing = ["umeed community center", "meraki"];
const serviceProviderOrg = [
  "Creatnet",
  "Khan Academy",
  "Teach For India",
  "Gandhi",
  "Arthan",
  "TechForGood",
  "Social sector law firms",
];
const intermediariesOrg = [
  "JPAL",
  "EPoD",
  "CPR",
  "IDInsight",
  "Sambodhi",
  "Outline India",
  "EvalDesign",
  "Samhita",
  "Sattva",
  "Dasra",
  "ATMA",
  "NCORE",
  "UnLtd",
  "Dalberg",
  "FSG",
  "Bridgespan",
];
const fundingOrgs = [
  "CSRs",
  "HNIs",
  "MSDF",
  "Gates",
  "World Bank",
  "UNICEF",
  "UNESCO",
];
const governanceConsultingOrgs = ["Samagra", "IIC", "BCG", "KPMG"];
const governmentFellowshipOrgs = [
  "CMGGA",
  "Gandhi Fellowship",
  "Azmi Premji Fellowship",
  "District Fellowships",
];
const bureaucracyOrgs = ["IAS"];
const educationButNotSdeg = ["ASB", "Shiv Nadar", "BYJU's", "Vedantu"];
const socialNotSdeg = ["health", "nutrition"];

const teachingDesg = ["teacher", "class teacher"];
const schoolLeadershipDesg = [
  "head teacher",
  "teacher coordinator",
  "curriculum coordinator",
  "assistant school leader",
  "School leader",
];
const enterpreneurDesg = ["founder", "ceo"];
const serviceProviderDesg = [
  "Project Associate",
  "Project Manager",
  "Program Associate",
  "Program Manager",
];
const intermediariesDesg = [
  "Research Associate",
  "Data Associate",
  "Consultant",
  "Manager",
];
const fundingDesg = ["Manager", "CSR"];
const governanceConsultingDesg = [
  "Associate",
  "Sr. Associate",
  "Consultant",
  "Manager",
];
const governmentFellowshipDesg = ["District Fellow", "Changemaker Fellow"];
const bureaucracyDesg = [
  "Deputy/Joint Commissioner",
  "Principal Secretary",
  "Chief Secretary",
  "Collector",
];
const politicsDesg = [
  "Ward councillors",
  "Municipality Head/Corporation Mayor",
  "MLA",
  "MP",
  "Ministers",
];

export const getPathway = (org, designation) => {
  let pathways = "";
  if (socialNotSdeg.includes(org))
    pathways = "Social impact for SEDG but non-Education";
  else if (educationButNotSdeg.includes(org))
    pathways =
      "Education initiatives but non-SEDG (Socio-Economically Disadvantaged Groups)";
  else if (politicsDesg.includes(designation)) pathways = "Politics";
  else if (bureaucracyOrgs.includes(org)) pathways = "Bureaucracy";
  else if (governmentFellowshipDesg.includes(designation))
    pathways = "Government Fellowship";
  else if (schools.includes(org) && teachingDesg.includes(designation))
    pathways = "Teaching";
  else if (schools.includes(org) && schoolLeadershipDesg.includes(designation))
    pathways = "School Leadership";
  else if (enterpreneurDesg.includes(designation) && schools.includes(org))
    pathways = "School Enterpreneurship";
  else if (communityMobilizing.includes(org)) pathways = "Community mobilizing";
  else if (
    serviceProviderOrg.includes(org) &&
    serviceProviderDesg.includes(designation)
  )
    pathways = "Service providers";
  else if (
    intermediariesOrg.includes(org) &&
    intermediariesDesg.includes(designation)
  )
    pathways = "Intermedaries";
  else if (fundingOrgs.includes(org) && fundingDesg.includes(designation))
    pathways = "Funding";
  else if (
    governanceConsultingOrgs.includes(org) &&
    governanceConsultingDesg.includes(designation)
  )
    pathways = "Government Consulting";
  else pathways = "Non-Education and non-SEDG";

  return pathways;
};
