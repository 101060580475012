import React from "react";

import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

function AlumniExperienceCard({ item }) {
  return (
    <Card>
      <CardHeader
        title={item?.companyName || item?.institutionName}
        subheader={item.startDate + " - " + item.endDate}
      />
      <CardContent>
        <Typography variant="subtitle1" color="textSecondary" component="p">
          {item?.designation || item?.course}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {item?.responsibilities || item?.level}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default AlumniExperienceCard;
